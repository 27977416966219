.logoLoginPage {
  padding-top: 10rem;
  padding-bottom: 5rem;
  height: 26rem;
}

.loginPageBackground {
  background-image: url("../../resources/loginPageBackground.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginPageForeground {
  backdrop-filter: blur(5px);
  height: 100vh;
}
