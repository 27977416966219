.logo {
  height: 50px;
  padding-left: 20px;
  padding-top: 5px;
}

.title {
  color: #fff;
  padding-left: 40px;
}
