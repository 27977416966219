.card {
  padding: 0.5rem;
}

.cardImage {
  height: 3rem;
}

.cardTitle {
  text-align: center;
  color: #fff;
}

.cardText {
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
}

.link {
  text-decoration: none;
  height: 100%;
  width: 100%;
}
