.descriptionBar {
  padding-left: 2rem;
  padding-top: 1.5rem;
  margin-bottom: -1.5rem;
}

.descriptionBarTitle {
  font-weight: 600;
}

.descriptionBarLine {
  width: 94%;
  margin-left: 0.8rem;
}

.row {
  margin-right: 0;
}
